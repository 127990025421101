<template>
  <div class="list_a_s">
    <div class="headers">
      <span @click="goUp()"></span>
      <span>拖车订单列表</span>
      <span @click="islogout">退出</span>
    </div>
	<!-- <div class="page"> -->
    <!-- <date-select @getDate="getDaySchedule"></date-select> -->
		<!-- <DateSelectNew @getDate="getDaySchedule"/> -->
   
  <!-- </div> -->
	
	<!-- <van-cell title="选择年月日" :value="date" @click="show = true" />
	<van-calendar v-model="show" :show-confirm="false" @confirm="onConfirmDate" :min-date="minDate" :max-date="maxDate"   /> -->
    <div class="list_a">
		 <div class="time_select_list">
			<div class="time_select_list_box">
				<div
					v-for="(item, index) in same_week"
					@click="selectTime(item)"
					:key="index"
					class="time_select"
				>
				<span>{{ item.week }}</span>
					<div class="time_select_lists">
						<span :class="[same_day == item.date ? 'active' : '', 'dis']">{{
							item.name
						}}</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="dates!=1" class="more" style="line-height: 41px;" @click="addDate()">更多</div> 
      
		<div>
			<van-list
			v-model="loading"
			:finished="finished"
			:immediate-check="false"
			finished-text="没有更多了"
			@load="onLoad()"
			:offset="10"
			>
			<div class="main_a" v-for="item in list_a" :key="item.id" @click="goDetail(item)">
				<p>订单号码：{{ item.order_number }}</p>
				<p>使用车辆：{{ item.plate_number }}——{{ item.brand_model }}</p>
				<p>预约拖运时间：{{ item.tow_time }}</p>
				<p>拖运状态：{{ item.StatusTxt }}</p>
				<p>拖运备注：{{ item.remark }}</p>
				<p>拖运车牌号：{{ item.carNumberPlate }}</p>
				<div class="btn">查看详情</div>
			</div>
			</van-list>
		</div>
    </div>
  </div>
</template>
<script>
import { Toast,Tab, Tabs } from "vant";
import {deleteCookie } from '../../utils/env'
import DateSelectNew from './components/DateSelectNew.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
export default {
  components: {
	  	[Tab.name]: Tab,
		[Tabs.name]: Tabs,
		DateSelectNew
		},
  data() {
    return {
      active: 2,
      list_a: [],
      week: [],
      same_week: [], //保存当前最新的时间
      same_day: "", //当天的时间
      the_day: "", //选中的时间

      page: 1, //请求第几页
      pageSize: 10, //每页请求的数量
      total: 0, //总共的数据条数

      loading: false,
      finished: false,
      refreshing: false,
      days:'7',
	  dates:null,
	  show:false,
	  date:"",
	  minDate:'',
	  maxDate:'',
    };
  },
  created() {
	  this.getDayList()
		  if(this.$route.query.itme){
			  this.same_day = this.$route.query.itme
			  this.page=1 //请求第几页
      			this.pageSize=10 //每页
			//   this.getData()
		  }


    // this.getData()
  },
  methods: {
		addDate(){
			if(this.days >=30){
				Toast('最多可以查看30天内的');
				this.dates = 1
			}else{
				this.days= Number(this.days)+Number(7)
			}
			

		},
		getDaySchedule(e){
			console.log(e)
			this.same_day = e.dateStr1
		},
		//选中日期
		onConfirmDate(date) {
			this.show = false;
			this.date = this.$moment(date).format('YYYY-MM-DD');
		},
		getDayList(){
			let data = [];
			this.start = this.getDay(+this.days);
			this.end = this.getDay();
			for (let i = (this.days-1); i >= 0; i--) {
			data.push(this.getDay(+i));
			}
			var date = data.reverse(); //得出一周的日期进行排序
			this.week = date;
			var date = this.week;
			var pkc = []; /* 用于存储展示的日期数据 */
			var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
			date.forEach((item, index) => {
				//循坏日期
				var f = new Date(item);
				var week = f.getDay(); //计算出星期几
				var str1 = item.split("/");
				var strs = str1[2];
				var strs_a = str1[0] + "-" + str1[1] + "-" + str1[2];

				var weeks = weekday[week]; /* 将计算出来的时间带入数字得出中文 */
				var time = Math.round(new Date(item) / 1000); //时间戳转换
				var s = {}; //用于存储每个日期对象
				s.date = item;
				s.name = strs;
				s.week = weeks;
				s.times = time;
				s.time_a = strs_a;
				pkc.push(s);
			});
			this.same_week = pkc;
			//pkc存储着今天的年月日星期几，时间戳等
			this.same_day = pkc[0].date; //今天的数据
			this.the_day = pkc[0].date; //今天的数据
			//console.log(this.same_day);
		},
		//获取数据
		//获取未拍摄列表
		getData(a, b) {
		Toast.loading({
			message: "加载中...",
			forbidClick: true,
		});
		let this_ = this;
		var obj ={}
		if (a || b) {
			obj = {
			page: a,
			pageSize: b,
			date: this.same_day,
			};
		} else {
			obj = {
			page: this_.page,
			pageSize: this_.pageSize,
			date: this.same_day,
			};
		}
		//this.$api.pay.schedule(params)是我自己封装的get请求接口
		 let data = encipherMent( JSON.stringify(obj))
        let param = new URLSearchParams();
        param.append("value",data);
        param.append("page",this_.page);
		this_.$http.post("/index.php/dispatch_self_send/self_list", param).then((res) => {
			  let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
			if (result.code == 0) {
				Toast.clear();
				let rows = result.data.data; //请求返回当页的列表
					this_.loading = false;
					this_.total = result.data.total;

				if (rows == null || rows.length === 0) {
				// 加载结束
					this_.finished = true;
					return;
				}
				// 将新数据与老数据进行合并
				this_.list_a = this_.list_a.concat(rows);

				//如果列表数据条数>=总条数，不再触发滚动加载
				if (this_.list_a.length >= this_.total) {
					this_.finished = true;
				}
			} if(result.code == -1){
					Toast.fail(result.msg);
					deleteCookie('token')
					this.$router.push('/login')
					//  window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
					// this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
					// 	if(res.data.code == 0){
					// 		let domain = this.zhuan(res.data.data)
					// 		let url = window.location.origin
					
					// 		window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
					// 	}
					// })
			} else {
				Toast.clear();
				// Toast.fail(res.data.msg);
			}
			});
		},
		onLoad() {
			(this.loading = true), this.page++;
			this.getData();
		},
		zhuan(url){
			let str = url
			let str1 = str.replace('https','')
			str1 = str1.replace('http','')
			let str2 = 'https'+ str1
			return str2
		},
		getDay(day) {
			var today = new Date();
			var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
			today.setTime(targetday_milliseconds); //注意，这行是关键代码
			var tYear = today.getFullYear();
			var tMonth = today.getMonth();
			var tDate = today.getDate();
			tMonth = this.doHandleMonth(tMonth + 1);
			tDate = this.doHandleMonth(tDate);
			return tYear + "/" + tMonth + "/" + tDate;
		},
		doHandleMonth(month) {
			var m = month;
			if (month.toString().length == 1) {
				m = month;
			}
			return m;
		},
		selectTime(item) {
			this.the_day = item.date;
			//添加样式
			this.same_day = item.date;
			//console.log(this.the_day);
			this.$forceUpdate();
		},
		goUp() {
			this.$router.go("-1");
		},
		goDetail(item){
			//console.log(item)
			this.$router.push({path:'/OrderDetail',query:{id:item.id,dispatch_id:item.dispatch_id}})    }
	},
  	watch: {
		same_day: function () {
		this.list_a = [];
		this.getData(1, 10);
		//   this.$http.post('/index.php/dispatch_self_send/self_list',{pag})
		},
		days:function(){
		//console.log(this.days)
		this.getDayList()
		}
	},
};
</script>

<style lang="scss" scoped>
.van-tabs__wrap {
  height: 1rem !important;
  color: #000 !important;
}
.van-tabs__nav {
  height: 100% !important;
}
.van-tabs__nav--card {
  margin: 0 !important;
  border: 0.02rem solid #000 !important;
}
.van-tabs__nav--card .van-tab.van-tab--active {
  color: #fff;
  background-color: rgba(0, 153, 255, 1);
}
.list_a {
  width: 97%;
  display: inline-block;
  // height: 5rem;
  position: relative;
  // margin: 0px auto;
  //   margin-top: 1rem;
  margin-left: 0.3rem;
  font-size: 0.35rem;
  padding-top: 0.2rem;
	.more{
		position: absolute;
		top: 25px;
		right: 25px;
	}
  .main_a {
    // height: 2.5rem;
    width: 98%;
    // border-bottom: 1px solid;
    margin-top: 10px;
    background-color: white;
    padding: 5px;
    border-radius: 4px;
    border-bottom: 1px solid;
  }
  .btn {
    height: 0.7rem;
    line-height: 0.7rem;
    text-align: center;
    background-color: rgba(22, 155, 213, 1);
    color: white;
    border-radius: 10px;
  }
  .time_select_list {
    font-size: 0.38rem;

    width:80%;
	overflow-x: scroll;
	.time_select_list_box{
		width: 2500px;
	
	}
	
	.time_select{
		float: left;
	}
	.time_select_lists{
		width: 50px;
		padding: 5px;
		border-radius: 9px;
		background-color: white;
	}
    span {
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .active {
    // color:red;
    // background-color: red;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: rgba(22, 155, 213, 1);
    border-radius: 50%;
    color: white;
  }
  .dis {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
</style>
<style lang="scss" scoped>
p {
  margin-bottom: 15px;
  margin-top: 15px;
}
html {
  // height: 100%;
  //   background-image: url('../../../../public/photo.jpg');
  background-size: 100%;
  body {
    height: 100%;
    #app {
      height: 100%;
    }
    // .list_a_s{
    //     height: 100%;
    //     background-image: url('../../../../public/back_4.jpg');
    //     background-size: 100%;
    //     // padding-top: 2rem;
    // }
    .headers {
      padding: 0 20px;
      background-color: cadetblue;
      height: 1rem;
      line-height: 1rem;
      color: white;
      font-size: 0.4rem;
      display: flex;

      justify-content: space-between;
      span {
        display: inline-block;
      }
      span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
      }
    }
  }
}

</style>










